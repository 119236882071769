import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_AIRPORTS_LAYER_ID = 'poiAirportsLayer';

const defaultPoiAirportsLayerConfig = {
    id: POI_AIRPORTS_LAYER_ID,
    title: 'POI Airports Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Airports"],
      colorscheme: "AirportsDefault",
      colors: [colors["AirportsDefault"]]
    }
};

var poiAirportsLayerConfig = defaultPoiAirportsLayerConfig;

export function getPoiAirportsLayerConfig() {
  return poiAirportsLayerConfig;
};

export function setPoiAirportsLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiAirportsLayerConfig = defaultPoiAirportsLayerConfig;
  } else {
    poiAirportsLayerConfig = newConfig;
  }
}

export default function PoiAirportsLayer() {
    const dispatch = useDispatch();
    const { poiAirportsLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiAirportsLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiAirportsLayer) {
            var currConfig = poiAirportsLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiAirportsLayer]
    );

    if (poiAirportsLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_AIRPORTS_LAYER_ID,
            filled: poiAirportsLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiAirportsLayerConfig.opacity,
            pointRadiusMinPixels: poiAirportsLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_AIRPORTS_LAYER_ID,
                    layerAttributes: { ...poiAirportsLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiAirportsLayerConfig.linecolor,
            lineWidthMinPixels: poiAirportsLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiAirportsLayerConfig,
                getFillColor: poiAirportsLayerConfig.legend,
                pointRadiusMinPixels: poiAirportsLayerConfig,
                getLineColor: poiAirportsLayerConfig,
                lineWidthMinPixels: poiAirportsLayerConfig,
                opacity: poiAirportsLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}