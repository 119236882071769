import { MAP_TYPES } from '@deck.gl/carto';
import { city, country, vision } from 'utils/urlParamUtil';

const POI_TOURISTAREA_SOURCE = 'poiTouristareaSource';

const source = {
    id: POI_TOURISTAREA_SOURCE,
    type: MAP_TYPES.QUERY,
    connection: process.env.REACT_APP_CONNECTION_NAME,
    data: `
        SELECT 
            *,
            "Tourist area" AS poi_type
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Touristarea_${vision}_${country}_${city}\`
    `
};

export default source;