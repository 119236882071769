import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colorScheme from 'assets/config/colorSchemes.json';
import { useCallback } from 'react';

export const DEMOGRAPHY_LAYER_ID = 'demographyLayer';

const defaultDemographyLayerConfig = {
  id: DEMOGRAPHY_LAYER_ID,
  title: 'Population Layer',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.4,
  linecolor: [255, 255, 255],
  linewidth: 1,
  pointradius: 4,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    attr: 'total_population',
    type: LEGEND_TYPES.BINS,
    labels: [5,10,25,50,75,100,250,500,1000,1250,1500,2000,3500,5000,10000,15000],
    colorscheme: "PopulationH3Default",
    colors: colorScheme["PopulationH3Default"]
  }
};

var demographyLayerConfig = defaultDemographyLayerConfig;

export function getDemographyLayerConfig() {
  return demographyLayerConfig;
};

export function setDemographyLayerConfig(newConfig=false) {
  if (newConfig === false) {
    demographyLayerConfig = defaultDemographyLayerConfig;
  } else {
    demographyLayerConfig = newConfig;
  }
}

export default function DemographyLayer() {
  const dispatch = useDispatch();
  const { demographyLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, demographyLayer?.source));
  const cartoLayerProps = useCartoLayerProps({ source });

  const getFilledColor = useCallback(
    (object) => {
      if (demographyLayer) {
        for (var i = 0; i < demographyLayerConfig.legend.labels.length; i++) {
          if (object.properties[demographyLayerConfig.legend.attr] <= demographyLayerConfig.legend.labels[i]) {
            return demographyLayerConfig.legend.colors[i];
          } 
        }
        return demographyLayerConfig.legend.colors[demographyLayerConfig.legend.labels.length-1];
      }
    }, [demographyLayer]
  );

  if (demographyLayer && source) {
    return new CartoLayer({
      ...cartoLayerProps,
      id: DEMOGRAPHY_LAYER_ID,
      filled: demographyLayerConfig.filled,
      getFillColor: getFilledColor,
      opacity : demographyLayerConfig.opacity,
      pointRadiusMinPixels: demographyLayerConfig.pointradius,
      headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
      getLineColor: demographyLayerConfig.linecolor,
      lineWidthMinPixels: demographyLayerConfig.linewidth,
      pickable: true,
      onDataLoad: (data) => {
        dispatch(
          updateLayer({
            id: DEMOGRAPHY_LAYER_ID,
            layerAttributes: { ...demographyLayerConfig },
          })
        );
        cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
      },
      updateTriggers: {
        getFillColor: demographyLayerConfig,
        getFillColor: demographyLayerConfig.legend,
        getLineColor: demographyLayerConfig,
        opacity: demographyLayerConfig,
      },
      onHover: (info) => {
        if (info?.object) {
          var htmlstring = "";
          for (const [key, value] of Object.entries(info.object.properties)) {
            var newValue;
            if (["purchasingpowerpercapita", "purchasingpowerindex", "agebelow15pct", "age15to59pct", "age60abovepct"].includes(key)) {
              newValue = (Math.round(value*100)/100);
            } else {
              newValue = value;
            }
            htmlstring = htmlstring + "<strong>" + key + "</strong>: " + newValue + "<br>";
          }
          info.object = {
            html: htmlstring,
            style: {},
          };
        }
      },
    });
  }
}
