import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import htmlForFeature from 'utils/htmlForFeature';
import { LEGEND_TYPES } from '@carto/react-ui';
import spritesheet2 from 'assets/img/spritesheet_newlogo.png';
import LabelledIconLayer from './LabelledIconLayer';
import colors from 'assets/config/colors.json';
export const STORE_LOCATION_LAYER_ID = 'storeLocationLayer';

const ICON_MAPPING = {
  clickcollect: {x:0, y:0, width:88, height:100, mask:false},
  standaloneowned: {x:1144, y:0, width:88, height:100, mask:false},
  fitoutleased: {x:704, y:0, width:88, height:100, mask:false},
  marker: {x:1320, y:0, width:88, height:100, mask:true},
  fitoutowned: {x:88, y:0, width:88, height:100, mask:false},
  franchise: {x:616, y:0, width:88, height:100, mask:false},
  mallleased: {x:1056, y:0, width:88, height:100, mask:false},
  mallowned: {x:176, y:0, width:88, height:100, mask:false},
  sellwithothers: {x:880, y:0, width:88, height:100, mask:false},
  standaloneleased: {x:1232, y:0, width:88, height:100, mask:false},
};

const defaultStoreLocationLayerConfig = {
  id: STORE_LOCATION_LAYER_ID,
  title: 'Store Location',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.6,
  linecolor: colors["Black"],
  linewidth: 2,
  pointradius: 3,
  showOpacityControl: false,
  labelled: true,
  labelsize: 0,
  labellinelimit: 10,
  labeloffsetx: 0,
  labeloffsety: 2,
  includenew: true,
  includeold: true,
  textcolor: colors["Black"],
  legend: {
    attr: 'store_format',
    collapsed: false,
    type: LEGEND_TYPES.CATEGORY,
    labels: [
      'Stand alone - Owned / Platform',
      'Fit Out - Owned / Platform',
      'Mall - Owned / Captor',
      'Stand alone - Leased /Platform',
      'Fit Out - Leased /Platform',
      'Mall - Leased / Captor',
      'Click & Collect/ Satellite',
      'Sell with Others',
      'Franchise',
      'Others',
    ],
    colorscheme: "StoreLocationDefault",
    colors: [
      [250, 75, 170],
      [144, 238, 144],
      [255, 165, 0],
      [128, 0, 128],
      [0, 100, 0],
      [0, 0, 0],
      [0, 0, 139],
      [173, 216, 230],
      [128, 128, 128],
      [127, 255, 212],
    ]
  }
};

var storeLocationLayerConfig = defaultStoreLocationLayerConfig;

export function getStoreLocationLayerConfig() {
  return storeLocationLayerConfig;
};

export function setStoreLocationLayerConfig(newConfig=false) {
  if (newConfig === false) {
    storeLocationLayerConfig = defaultStoreLocationLayerConfig;
  } else {
    storeLocationLayerConfig = newConfig;
  }
}

export default function StoreLocationLayer() {
  const { storeLocationLayer } = useSelector((state) => state.carto.layers);
  const storeLocation = useSelector((state) => state.polygon.storeLocation);

  const getFilledColor = useCallback(
    (object) => {
      if (storeLocationLayer) {
        for (var i = 0; i < storeLocationLayerConfig.legend.labels.length; i++) {
          if (object.properties[storeLocationLayerConfig.legend.attr] === storeLocationLayerConfig.legend.labels[i]) {
            return storeLocationLayerConfig.legend.colors[i];
          } 
        }
        return storeLocationLayerConfig.legend.colors[storeLocationLayerConfig.legend.labels.length-1];
      }
    }, [storeLocationLayer]
  );
  const getLinesColor = useCallback(
    () => {
      if (storeLocationLayer) {
	      return storeLocationLayerConfig.linecolor;
      }
    }, [storeLocationLayer]
  );
  const getIcon = useCallback((object) => {
    if (storeLocationLayer) {
      const desc = object.properties.type;
      const format = object.properties.store_format;
      if (desc === undefined || desc == 'new') {
        return "marker";
      } else if (format == 'Stand alone - Owned / Platform') {
        return "standaloneowned";
      } else if (format == 'Fit Out - Owned / Platform') {
        return "fitoutowned";
      } else if (format == 'Mall - Owned / Captor') {
        return "mallowned";
      } else if (format == 'Stand alone - Leased /Platform') {
        return "standaloneleased";
      } else if (format == 'Fit Out - Leased /Platform') {
        return "fitoutleased";
      } else if (format == 'Mall - Leased / Captor') {
        return "mallleased";
      } else if (format == 'Click & Collect/ Satellite') {
        return "clickcollect";
      } else if (format == 'Sell with Others') {
        return "sellwithothers";
      } else if (format == 'Franchise') {
        return "franchise";
      } else {
	      return "marker";
      }
    }
  }, [storeLocationLayer]);
  const getTextPosition = useCallback((object) => {
    if (storeLocationLayer) {
      const coords = object.geometry.coordinates;
      var labelCoords = [coords[0]+(storeLocationLayerConfig.labeloffsetx/1000), coords[1]+(storeLocationLayerConfig.labeloffsety/1000)];
      return labelCoords;
    }
  }, [storeLocationLayer]);
  const getTexts = useCallback(
    (object) => {
      if (storeLocationLayer) {
        if (storeLocationLayerConfig.labelled) {
          var finalstring = "";
          var textstring = object.properties.storename;
          if (object.properties.opening_date) {
            textstring = `${object.properties.storename} (${object.properties.opening_date})`;
          } 
          while (textstring.length > storeLocationLayerConfig.labellinelimit) {
            if (finalstring.length > 0) {
              finalstring = finalstring + "\n";
            }
            finalstring = finalstring + textstring.substring(0, storeLocationLayerConfig.labellinelimit);
            textstring = textstring.substring(storeLocationLayerConfig.labellinelimit, textstring.length);
          }
          if (finalstring.length > 0) {
            finalstring = finalstring + "\n";
          }
          finalstring = finalstring + textstring;
          return finalstring;
        } else {
          return;
        }
      }
    }, [storeLocationLayer]
  );

  if (storeLocationLayer && storeLocation) {
    var includedStoreLocations = [];
    for (var i = 0; i < storeLocation.length; i++) {
      if (storeLocationLayerConfig.includeold && storeLocation[i].properties.type === 'existing') {
        includedStoreLocations.push(storeLocation[i]);
      } else if (storeLocationLayerConfig.includenew && storeLocation[i].properties.type === 'new') {
        includedStoreLocations.push(storeLocation[i]);
      }
    }
    return new LabelledIconLayer({
      id: STORE_LOCATION_LAYER_ID,
      data: includedStoreLocations,
      opacity: storeLocationLayerConfig.opacity,
      pickable: true,
      iconAtlas: spritesheet2,
      iconMapping: ICON_MAPPING,
      getIcon: getIcon,
      sizeScale: 6,
      getPosition: d => d.geometry.coordinates,
      getLineColor: getLinesColor,
      getLineWidth: 2,
      filled: storeLocationLayerConfig.filled,
      lineWidthScale: 20,
      lineWidthMinPixels: storeLocationLayerConfig.linewidth,
      getSize: d => 5,
      getColor: getFilledColor,
      getText: getTexts,
      getTextSize: storeLocationLayerConfig.labelsize,
      getTextColor: storeLocationLayerConfig.textcolor,
      getTextPosition: getTextPosition,
      updateTriggers: {
        getFillColor: storeLocationLayerConfig,
        getFillColor: storeLocationLayerConfig.legend,
        pointRadiusMinPixels: storeLocationLayerConfig,
        getLineColor: storeLocationLayerConfig.linecolor,
        lineWidthMinPixels: storeLocationLayerConfig,
        opacity: storeLocationLayerConfig,
        getTextSize: storeLocationLayerConfig.labelsize,
        getTextColor: storeLocationLayerConfig,
        getText: [storeLocationLayerConfig.labelled, storeLocationLayerConfig.labellinelimit],
        getTextSize: storeLocationLayerConfig.labellinelimit,
      },
      onClick: (info) => {
        console.log(info)
      },
      onHover: (info) => {
        if (info?.object) {
          let {geometry, ...object} = info.object.properties;
          var display = {...info.object};
          display.properties = object;
          info.object = {
            html: htmlForFeature({ feature: display }),
            style: {},
          };
        }
      },
    });
  }
}
