import { MAP_TYPES } from '@deck.gl/carto';
import { city, country, vision } from 'utils/urlParamUtil';

const TOESTIMATION_SOURCE_ID = 'toestimationSource';

const source = {
    id: TOESTIMATION_SOURCE_ID,
    type: MAP_TYPES.QUERY,
    connection: process.env.REACT_APP_CONNECTION_NAME,
    data: `
        SELECT 
            h3,
            ST_ASGEOJSON(geom) AS geojson,
            predicted_annual_turnover,
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_toestimationresult\`
        WHERE
            modelname = @modelName
            AND UPPER(city) = UPPER("${city}")
            AND UPPER(country) = UPPER("${country}")
            AND sales_area = @salesArea
            AND store_format = @storeFormat
            AND store_age = @storeAge
    `,
    queryParameters: {
        'modelName': "vision-tool-4w7t.carto.bqml_toestimationmodel_indiatier1model",
        'salesArea': 1500,
        'storeFormat': "Stand alone",
        'storeAge': 2
    }
};

export default source;

