import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_SUPERMARKET_LAYER_ID = 'poiSupermarketLayer';

const defaultPoiSupermarketLayerConfig = {
    id: POI_SUPERMARKET_LAYER_ID,
    title: 'POI Supermarket Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Supermarket"],
      colorscheme: "SupermarketDefault",
      colors: [colors["SupermarketDefault"]]
    }
};

var poiSupermarketLayerConfig = defaultPoiSupermarketLayerConfig;

export function getPoiSupermarketLayerConfig() {
  return poiSupermarketLayerConfig;
};

export function setPoiSupermarketLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiSupermarketLayerConfig = defaultPoiSupermarketLayerConfig;
  } else {
    poiSupermarketLayerConfig = newConfig;
  }
}

export default function PoiSupermarketLayer() {
    const dispatch = useDispatch();
    const { poiSupermarketLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiSupermarketLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiSupermarketLayer) {
            var currConfig = poiSupermarketLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiSupermarketLayer]
    );

    if (poiSupermarketLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_SUPERMARKET_LAYER_ID,
            filled: poiSupermarketLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiSupermarketLayerConfig.opacity,
            pointRadiusMinPixels: poiSupermarketLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_SUPERMARKET_LAYER_ID,
                    layerAttributes: { ...poiSupermarketLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiSupermarketLayerConfig.linecolor,
            lineWidthMinPixels: poiSupermarketLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiSupermarketLayerConfig,
                getFillColor: poiSupermarketLayerConfig.legend,
                pointRadiusMinPixels: poiSupermarketLayerConfig,
                getLineColor: poiSupermarketLayerConfig,
                lineWidthMinPixels: poiSupermarketLayerConfig,
                opacity: poiSupermarketLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}