import { useCartoLayerProps } from "@carto/react-api";
import { selectSourceById, updateLayer } from "@carto/react-redux";
import { LEGEND_TYPES } from "@carto/react-ui";
import { CartoLayer } from "@deck.gl/carto";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import colorScheme from 'assets/config/colorSchemes.json';
import htmlForFeature from "utils/htmlForFeature";
import { useCallback } from "react";
import { setSelectedHex } from "store/layerSlice";
import { colorBins } from "@deck.gl/carto/typed";
import { setDrawingGeometry, setViewing } from "store/polygonSlice";

export const TO_ESTIMATION_LAYER_ID = 'toEstimationLayer';

const defaultToEstimationLayerConfig = {
    id: TO_ESTIMATION_LAYER_ID,
    title: 'TO Estimation Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.4,
    linecolor: [0, 0, 0],
    linewidth: 1,
    pointradius: 2,
    aggreslevel: 4,
    showOpacityControl: false,
    legend: {
        collapsed: false,
        attr: 'predicted_annual_turnover',
        type: LEGEND_TYPES.BINS,
        labels: [10000, 50000, 100000, 250000, 500000, 750000, 1000000],
        colorscheme: "OnlineTurnoverDefault",
        colors: colorScheme["OnlineTurnoverDefault"]
    }
};

var toEstimationLayerConfig = defaultToEstimationLayerConfig;
export function getToEstimationLayerConfig() {
    return toEstimationLayerConfig;
};
export function setToEstimationLayerConfig(newConfig=false) {
    if (newConfig === false) {
        toEstimationLayerConfig = defaultToEstimationLayerConfig;
    } else {
        toEstimationLayerConfig = newConfig;
    }
}

export default function TOEstimationLayer() {
    const dispatch = useDispatch();
    const { toEstimationLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, toEstimationLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        (object) => {
          if (toEstimationLayer) {
            for (var i = 0; i < toEstimationLayerConfig.legend.labels.length; i++) {
              if (object.properties[toEstimationLayerConfig.legend.attr] <= toEstimationLayerConfig.legend.labels[i]) {
                return toEstimationLayerConfig.legend.colors[i];
              } 
            }
            return toEstimationLayerConfig.legend.colors[toEstimationLayerConfig.legend.labels.length-1];
          }
        }, [toEstimationLayerConfig]
    );

    if (toEstimationLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: TO_ESTIMATION_LAYER_ID,
            geoColumn: 'h3',
            aggregationExp: `AVG(predicted_annual_turnover) AS predicted_annual_turnover, MAX(geojson) AS geojson`,
            aggregationResLevel: toEstimationLayerConfig.aggreslevel,
            getElevation: d => 0,
            filled: toEstimationLayerConfig.filled,
            getFillColor: colorBins({
                attr: toEstimationLayerConfig.legend.attr,
                domain: toEstimationLayerConfig.legend.labels,
                colors: toEstimationLayerConfig.legend.colors,
            }),
            opacity : toEstimationLayerConfig.opacity,
            pointRadiusMinPixels: toEstimationLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            getLineColor: toEstimationLayerConfig.linecolor,
            lineWidthMinPixels: toEstimationLayerConfig.linewidth,
            pickable: true,
            onDataLoad: (data) => {
                dispatch(
                    updateLayer({
                        id: TO_ESTIMATION_LAYER_ID,
                        layerAttributes: { ...toEstimationLayerConfig },
                    })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            updateTriggers: {
                getFillColor: toEstimationLayerConfig,
                getFillColor: toEstimationLayerConfig.legend,
                getLineColor: toEstimationLayerConfig,
                opacity: toEstimationLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                    info.object = {
                        html: "<strong>Estimated annual TO</strong>: " + parseFloat(info.object.properties.predicted_annual_turnover).toFixed(2),
                        style: {},
                    };
                  }
            },
            onClick: (info) => {
                if (info?.object) {
                    dispatch(setDrawingGeometry([
                        {type: "Feature", geometry: JSON.parse(info.object.properties.geojson), properties: info.object.properties, id: info.object.id}
                    ]));
                    dispatch(setViewing(true));
                    dispatch(setSelectedHex(info.object.id));
                }
            }
        });
    }
}