import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colorScheme from 'assets/config/colorSchemes.json';
import { useCallback } from 'react';

export const PURCHASING_POWER_LAYER_ID = 'purchasingPowerLayer';

const defaultPurchasingLayerConfig = {
  id: PURCHASING_POWER_LAYER_ID,
  title: 'Purchasing Power Layer',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.4,
  linecolor: [0, 0, 0],
  linewidth: 1,
  pointradius: 2,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    attr: 'purchasingpowerpercapita',
    type: LEGEND_TYPES.BINS,
    labels: [16000, 17000, 17500, 18000, 18500, 19000, 19500, 20000, 20500, 21500, 22500, 23500, 24000, 24500, 25000, 25500],
    colorscheme: "PurchasingH3Default",
    colors: colorScheme["PurchasingH3Default"]
  }
};

var purchasingLayerConfig = defaultPurchasingLayerConfig;

export function getPurchasingPowerLayerConfig() {
  return purchasingLayerConfig;
};

export function setPurchasingPowerLayerConfig(newConfig=false) {
  if (newConfig === false) {
    purchasingLayerConfig = defaultPurchasingLayerConfig;
  } else {
    purchasingLayerConfig = newConfig;
  }
}

export default function PurchasingPowerLayer() {
  const dispatch = useDispatch();
  const { purchasingPowerLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, purchasingPowerLayer?.source));
  const cartoLayerProps = useCartoLayerProps({ source });

  const getFilledColor = useCallback(
    (object) => {
      if (purchasingPowerLayer) {
        for (var i = 0; i < purchasingLayerConfig.legend.labels.length; i++) {
          if (object.properties[purchasingLayerConfig.legend.attr] <= purchasingLayerConfig.legend.labels[i]) {
            return purchasingLayerConfig.legend.colors[i];
          } 
        }
        return purchasingLayerConfig.legend.colors[purchasingLayerConfig.legend.labels.length-1];
      }
    }, [purchasingPowerLayer]
  );

  if (purchasingPowerLayer && source) {
    return new CartoLayer({
      ...cartoLayerProps,
      id: PURCHASING_POWER_LAYER_ID,
      filled: purchasingLayerConfig.filled,
      getFillColor: getFilledColor,
      opacity : purchasingLayerConfig.opacity,
      pointRadiusMinPixels: purchasingLayerConfig.pointradius,
      headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
      getLineColor: purchasingLayerConfig.linecolor,
      lineWidthMinPixels: purchasingLayerConfig.linewidth,
      pickable: true,
      onDataLoad: (data) => {
        dispatch(
          updateLayer({
            id: PURCHASING_POWER_LAYER_ID,
            layerAttributes: { ...purchasingLayerConfig },
          })
        );
        cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
      },
      updateTriggers: {
        getFillColor: purchasingLayerConfig,
        getFillColor: purchasingLayerConfig.legend,
        getLineColor: purchasingLayerConfig,
        opacity: purchasingLayerConfig,
      },
      onHover: (info) => {
        if (info?.object) {
          info.object = {
            html: "<strong>Purchasing power/capita</strong>: " + Math.round(info.object.properties.purchasingpowerpercapita)
                + "<br/><strong>Purchasing power index</strong>: " + info.object.properties.purchasingpowerindex,
            style: {},
          };
        }
      },
    });
  }
}
