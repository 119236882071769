const pageMapping = {
  cityboundary: 2,
  opportunityarea: 3,
  livingarea: 4,
  storelocation: 5,
  voc: 6,
  ecm: 1,
};

function countryFormatter(country) {
  const capitalized = country.toUpperCase();
  const formattedCountry = capitalized;
  return formattedCountry;
}

function cityFormatter(city) {
  const capitalized = city.toUpperCase();
  const formattedCity = capitalized;
  return formattedCity;
}

const params = new URLSearchParams(window.location.search);
var noedit = 0;
if (params.has('noedit')) {
  noedit = params.get('noedit');
} else if (params.has('page') && pageMapping[params.get('page')] == 0) {
  noedit = 1;
}
export var noedit;
export const city = params.has('city') ? cityFormatter(params.get('city')) : '';
export const country = params.has('country') ? countryFormatter(params.get('country')) : '';
export const vision = params.has('vision') ? params.get('vision').toUpperCase() : '';
export const page = params.has('page') ? pageMapping[params.get('page')] : 0;