import { createSlice } from '@reduxjs/toolkit';

const layerslice = createSlice({
  name: 'layer',
  initialState: {
    foundSource: [],
    layerOrdering: [],
    savingImage: false,
    hideHeader: false,
    selectedHex: null,
  },
  reducers: {
      setFoundSource: (state, action) => {
          state.foundSource = action.payload;
      },
      setLayerOrdering: (state, action) => {
        state.layerOrdering = action.payload;
    },
    setSavingImage: (state, action) => {
      state.savingImage = action.payload;
    },
    setHideHeader: (state, action) => {
      state.hideHeader = action.payload;
    },
    setSelectedHex: (state, action) => {
      state.selectedHex = action.payload;
    },
  },
});

export default layerslice.reducer;

export const { setFoundSource, setLayerOrdering, setSavingImage, setHideHeader, setSelectedHex } = layerslice.actions;