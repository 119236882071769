import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_MAJORBUSHUB_LAYER_ID = 'poiMajorbushubLayer';

const defaultPoiMajorbushubLayerConfig = {
    id: POI_MAJORBUSHUB_LAYER_ID,
    title: 'POI Major bus hub Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Major bus hub"],
      colorscheme: "MajorbushubDefault",
      colors: [colors["MajorbushubDefault"]]
    }
};

var poiMajorbushubLayerConfig = defaultPoiMajorbushubLayerConfig;

export function getPoiMajorbushubLayerConfig() {
  return poiMajorbushubLayerConfig;
};

export function setPoiMajorbushubLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiMajorbushubLayerConfig = defaultPoiMajorbushubLayerConfig;
  } else {
    poiMajorbushubLayerConfig = newConfig;
  }
}

export default function PoiMajorbushubLayer() {
    const dispatch = useDispatch();
    const { poiMajorbushubLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiMajorbushubLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiMajorbushubLayer) {
            var currConfig = poiMajorbushubLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiMajorbushubLayer]
    );

    if (poiMajorbushubLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_MAJORBUSHUB_LAYER_ID,
            filled: poiMajorbushubLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiMajorbushubLayerConfig.opacity,
            pointRadiusMinPixels: poiMajorbushubLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_MAJORBUSHUB_LAYER_ID,
                    layerAttributes: { ...poiMajorbushubLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiMajorbushubLayerConfig.linecolor,
            lineWidthMinPixels: poiMajorbushubLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiMajorbushubLayerConfig,
                getFillColor: poiMajorbushubLayerConfig.legend,
                pointRadiusMinPixels: poiMajorbushubLayerConfig,
                getLineColor: poiMajorbushubLayerConfig,
                lineWidthMinPixels: poiMajorbushubLayerConfig,
                opacity: poiMajorbushubLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}