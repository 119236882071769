import { MAP_TYPES } from '@deck.gl/carto';
import { city, country, vision } from 'utils/urlParamUtil';

const DEMOGRAPHY_SOURCE = 'demographySource';

const source = {
    id: DEMOGRAPHY_SOURCE,
    type: MAP_TYPES.QUERY,
    connection: process.env.REACT_APP_CONNECTION_NAME,
    data: `
      SELECT 
        * except(total_population), 
        CASE WHEN total_population IS NULL THEN 0 ELSE total_population END AS total_population 
      FROM 
        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.demographygeom_${vision}_${country}_${city}\`
    `
};

export default source;
