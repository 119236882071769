import { useSelector } from 'react-redux';
import htmlForFeature from 'utils/htmlForFeature';
import { LEGEND_TYPES } from '@carto/react-ui';
import { useCallback } from 'react';
import LabelledPolygonLayer from './LabelledPolygonLayer';
import * as turf from '@turf/turf';
import colors from 'assets/config/colors.json';

export const OPPORTUNITY_AREA_LAYER_ID = 'opportunityAreaLayer';

const defaultOpportunityAreaLayerConfig = {
  id: OPPORTUNITY_AREA_LAYER_ID,
  title: 'Opportunity Area',
  visible: false,
  switchable: false,
  filled: false,
  opacity: 0.6,
  linecolor: colors["OpportunityAreaDefault"],
  linewidth: 4,
  pointradius: 2,
  showOpacityControl: false,
  labelled: true,
  labelsize: 12,
  labellinelimit: 10,
  textcolor: colors["Black"],
  legend: {
    collapsed: false,
    type: LEGEND_TYPES.CATEGORY,
    labels: ["Opportunity Area"],
    colorscheme: "White",
    colors: [colors["White"]]
  }
};

var oppAreaLayerConfig = defaultOpportunityAreaLayerConfig;

export function getOpportunityAreaLayerConfig() {
  return oppAreaLayerConfig;
};

export function setOpportunityAreaLayerConfig(newConfig=false) {
  if (newConfig === false) {
    oppAreaLayerConfig = defaultOpportunityAreaLayerConfig;
  } else {
    oppAreaLayerConfig = newConfig;
  }
}

export default function OpportunityAreaLayer() {
  const { opportunityAreaLayer } = useSelector((state) => state.carto.layers);
  const opportunityArea = useSelector((state) => state.polygon.opportunityArea);
  const selectedId = useSelector((state) => state.polygon.selectedPolygonId);

  const getFilledColor = useCallback(
    () => {
      if (opportunityAreaLayer) {
	      return oppAreaLayerConfig.legend.colors[0];
      }
    }, [opportunityAreaLayer]
  );
  const getLinesColor = useCallback(
    (object) => {
      if (opportunityAreaLayer) {
	      if (object.properties.id !== null && object.properties.id === selectedId) {
          return colors["SelectedBoundaryDefault"];
	      }
	      return oppAreaLayerConfig.linecolor;
      }
    }, [opportunityAreaLayer]
  );
  const getTexts = useCallback(
    (object) => {
      if (opportunityAreaLayer) {
        if (oppAreaLayerConfig.labelled) {
          var finalstring = "";
          var textstring = object.properties.oppareaname;
          while (textstring.length > oppAreaLayerConfig.labellinelimit) {
            if (finalstring.length > 0) {
              finalstring = finalstring + "\n";
            }
            finalstring = finalstring + textstring.substring(0, oppAreaLayerConfig.labellinelimit);
            textstring = textstring.substring(oppAreaLayerConfig.labellinelimit, textstring.length);
          }
          if (finalstring.length > 0) {
            finalstring = finalstring + "\n";
          }
          finalstring = finalstring + textstring;
          return finalstring;
        } else {
          return;
        }
      }
    }, [opportunityAreaLayer, oppAreaLayerConfig]
  );
  const getTextPosition = useCallback((object) => {
    if (opportunityAreaLayer) {
      const centerofMass = turf.centerOfMass(object).geometry.coordinates;
      return centerofMass;
    }
  }, [opportunityAreaLayer]);

  if (opportunityAreaLayer && opportunityArea) {
    return new LabelledPolygonLayer({
      id: OPPORTUNITY_AREA_LAYER_ID,
      data: opportunityArea,
      pickable: true,
      filled: oppAreaLayerConfig.filled,
      lineWidthScale: 20,
      lineWidthMinPixels: oppAreaLayerConfig.linewidth,
      getFillColor: getFilledColor,
      opacity: oppAreaLayerConfig.opacity,
      getLineColor: getLinesColor,
      getLineWidth: 2,
      getText: getTexts,
      getTextSize: oppAreaLayerConfig.labelsize,
      getTextColor: oppAreaLayerConfig.textcolor,
      getPosition: getTextPosition,
      updateTriggers: {
        getFillColor: selectedId,
        getLineColor: selectedId,
        getFillColor: oppAreaLayerConfig,
        getFillColor: oppAreaLayerConfig.legend,
        pointRadiusMinPixels: oppAreaLayerConfig,
        getLineColor: oppAreaLayerConfig.linecolor,
        lineWidthMinPixels: oppAreaLayerConfig,
        opacity: oppAreaLayerConfig,
        getTextSize: oppAreaLayerConfig.labelsize,
        getTextColor: oppAreaLayerConfig,
        getText: [oppAreaLayerConfig.labelled, oppAreaLayerConfig.labellinelimit],
        getTextSize: oppAreaLayerConfig.labellinelimit,
      },
      onHover: (info) => {
        if (info?.object) {
	        let {geometry, id, sport_market_size, enriched, createdat, updatedat, visionid, name, ...object} = info.object.properties;
          var display = {...info.object};
          display.properties = object;
          for (let d in display.properties) {
	          if (Number.isInteger(display.properties[d])) {
              display.properties[d] = Math.round(display.properties[d]*100) / 100;
	          }
          }
          info.object = {
            html: htmlForFeature({ feature: display }),
            style: {},
          };
        }
      },
    });
  }
}
