import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_METROLINES_LAYER_ID = 'poiMetrolinesLayer';

const defaultPoiMetrolinesLayerConfig = {
    id: POI_METROLINES_LAYER_ID,
    title: 'POI Metro lines Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Metro lines"],
      colorscheme: "MetrolinesDefault",
      colors: [colors["MetrolinesDefault"]]
    }
};

var poiMetrolinesLayerConfig = defaultPoiMetrolinesLayerConfig;

export function getPoiMetrolinesLayerConfig() {
  return poiMetrolinesLayerConfig;
};

export function setPoiMetrolinesLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiMetrolinesLayerConfig = defaultPoiMetrolinesLayerConfig;
  } else {
    poiMetrolinesLayerConfig = newConfig;
  }
}

export default function PoiMetrolinesLayer() {
    const dispatch = useDispatch();
    const { poiMetrolinesLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiMetrolinesLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiMetrolinesLayer) {
            var currConfig = poiMetrolinesLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiMetrolinesLayer]
    );

    if (poiMetrolinesLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_METROLINES_LAYER_ID,
            filled: poiMetrolinesLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiMetrolinesLayerConfig.opacity,
            pointRadiusMinPixels: poiMetrolinesLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_METROLINES_LAYER_ID,
                    layerAttributes: { ...poiMetrolinesLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiMetrolinesLayerConfig.linecolor,
            lineWidthMinPixels: poiMetrolinesLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiMetrolinesLayerConfig,
                getFillColor: poiMetrolinesLayerConfig.legend,
                pointRadiusMinPixels: poiMetrolinesLayerConfig,
                getLineColor: poiMetrolinesLayerConfig,
                lineWidthMinPixels: poiMetrolinesLayerConfig,
                opacity: poiMetrolinesLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}