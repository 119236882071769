import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_SCHOOL_LAYER_ID = 'poiSchoolLayer';

const defaultPoiSchoolLayerConfig = {
    id: POI_SCHOOL_LAYER_ID,
    title: 'POI School Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["School"],
      colorscheme: "SchoolDefault",
      colors: [colors["SchoolDefault"]]
    }
};

var poiSchoolLayerConfig = defaultPoiSchoolLayerConfig;

export function getPoiSchoolLayerConfig() {
  return poiSchoolLayerConfig;
};

export function setPoiSchoolLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiSchoolLayerConfig = defaultPoiSchoolLayerConfig;
  } else {
    poiSchoolLayerConfig = newConfig;
  }
}

export default function PoiSchoolLayer() {
    const dispatch = useDispatch();
    const { poiSchoolLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiSchoolLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiSchoolLayer) {
            var currConfig = poiSchoolLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiSchoolLayer]
    );

    if (poiSchoolLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_SCHOOL_LAYER_ID,
            filled: poiSchoolLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiSchoolLayerConfig.opacity,
            pointRadiusMinPixels: poiSchoolLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_SCHOOL_LAYER_ID,
                    layerAttributes: { ...poiSchoolLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiSchoolLayerConfig.linecolor,
            lineWidthMinPixels: poiSchoolLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiSchoolLayerConfig,
                getFillColor: poiSchoolLayerConfig.legend,
                pointRadiusMinPixels: poiSchoolLayerConfig,
                getLineColor: poiSchoolLayerConfig,
                lineWidthMinPixels: poiSchoolLayerConfig,
                opacity: poiSchoolLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}