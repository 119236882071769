import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_NATURALBARRIER_LAYER_ID = 'poiNaturalbarrierLayer';

const defaultPoiNaturalbarrierLayerConfig = {
    id: POI_NATURALBARRIER_LAYER_ID,
    title: 'POI Natural barrier Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Natural barrier"],
      colorscheme: "NaturalbarrierDefault",
      colors: [colors["NaturalbarrierDefault"]]
    }
};

var poiNaturalbarrierLayerConfig = defaultPoiNaturalbarrierLayerConfig;

export function getPoiNaturalbarrierLayerConfig() {
  return poiNaturalbarrierLayerConfig;
};

export function setPoiNaturalbarrierLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiNaturalbarrierLayerConfig = defaultPoiNaturalbarrierLayerConfig;
  } else {
    poiNaturalbarrierLayerConfig = newConfig;
  }
}

export default function PoiNaturalbarrierLayer() {
    const dispatch = useDispatch();
    const { poiNaturalbarrierLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiNaturalbarrierLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiNaturalbarrierLayer) {
            var currConfig = poiNaturalbarrierLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiNaturalbarrierLayer]
    );

    if (poiNaturalbarrierLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_NATURALBARRIER_LAYER_ID,
            filled: poiNaturalbarrierLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiNaturalbarrierLayerConfig.opacity,
            pointRadiusMinPixels: poiNaturalbarrierLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_NATURALBARRIER_LAYER_ID,
                    layerAttributes: { ...poiNaturalbarrierLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiNaturalbarrierLayerConfig.linecolor,
            lineWidthMinPixels: poiNaturalbarrierLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiNaturalbarrierLayerConfig,
                getFillColor: poiNaturalbarrierLayerConfig.legend,
                pointRadiusMinPixels: poiNaturalbarrierLayerConfig,
                getLineColor: poiNaturalbarrierLayerConfig,
                lineWidthMinPixels: poiNaturalbarrierLayerConfig,
                opacity: poiNaturalbarrierLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}