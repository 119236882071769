import { useSelector, useDispatch } from 'react-redux';
import { updateLayer } from '@carto/react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import htmlForFeature from 'utils/htmlForFeature';
import { useCallback } from 'react';
import { LEGEND_TYPES } from '@carto/react-ui';

export const SEGMENT_LAYER_ID = 'segmentLayer';

const defaultSegmentLayerConfig = {
  id: SEGMENT_LAYER_ID,
  title: 'Customer VOC Layer',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.4,
  linecolor: [0, 0, 0],
  linewidth: 1,
  pointradius: 2,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    type: LEGEND_TYPES.CATEGORY,
    labels: ['Premium-letes', 'Expert-letes', 'Aspire-letes', 'Value-exercisers', 'Wisely-actives', 'Promo-actives', 'Easy-actives'],
    colors: [ [255, 109, 71], [75, 75, 255], [120, 120, 120], [150, 0, 255], [255, 200, 0], [255, 150, 255], [0, 160, 160] ]
  }
};

var segmentLayerConfig = defaultSegmentLayerConfig;

export function getSegmentLayerConfig() {
  return segmentLayerConfig;
};

export function setSegmentLayerConfig(newConfig=false) {
  if (newConfig === false) {
    segmentLayerConfig = defaultSegmentLayerConfig;
  } else {
    segmentLayerConfig = newConfig;
  }
}

export default function SegmentLayer() {
  const dispatch = useDispatch();
  const { segmentLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, segmentLayer?.source));
  const cartoLayerProps = useCartoLayerProps({ source });

  const getFilledColor = useCallback(
    (object) => {
      if (segmentLayer) {
        let maxValue = 0;
        let maxColor = [255, 255, 255];
        for (var i = 0; i < segmentLayerConfig.legend.labels.length; i++) {
          var currLabel = segmentLayerConfig.legend.labels[i].toLowerCase().replaceAll('-', '_');
          var currValue = object.properties[currLabel];
          if (currValue > maxValue) {
            maxValue = currValue;
            maxColor = segmentLayerConfig.legend.colors[i];
          } 
        }
        return maxColor;
      }
    }, [segmentLayer]
  );

  if (segmentLayer && source) {
    return new CartoLayer({
      ...cartoLayerProps,
      id: SEGMENT_LAYER_ID,
      filled: segmentLayerConfig.filled,
      getFillColor: getFilledColor,
      opacity : segmentLayerConfig.opacity,
      pointRadiusMinPixels: segmentLayerConfig.pointradius,
      headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
      getLineColor: segmentLayerConfig.linecolor,
      lineWidthMinPixels: segmentLayerConfig.linewidth,
      pickable: true,
      onDataLoad: (data) => {
        dispatch(
          updateLayer({
            id: SEGMENT_LAYER_ID,
            layerAttributes: { ...segmentLayerConfig },
          })
        );
        cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
      },
      updateTriggers: {
        getFillColor: segmentLayerConfig,
        getFillColor: segmentLayerConfig.legend,
        getLineColor: segmentLayerConfig,
        opacity: segmentLayerConfig,
      },
      onHover: (info) => {
        if (info?.object) {
          var display = {...info.object};
          for (let d in display.properties) {
            if (segmentLayerConfig.legend.labels.includes(d)) {
              display.properties[d] = Math.round(display.properties[d]*1000/display.properties.total_respondent, 2)/10 + "%"
            }
          }
          info.object = {
            html: htmlForFeature({ feature: display }),
            style: {},
          };
        }
      },
    });
  }
}
