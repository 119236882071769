import { CompositeLayer, GeoJsonLayer, TextLayer } from 'deck.gl';
import * as turf from '@turf/turf';

export default class LabelledPolygonLayer extends CompositeLayer {
    updateState({props, changeFlags}) {
        if (changeFlags.dataChanged) {
            const subLayerData = [];
            props.data.forEach((object, index) => {
                var {type, coordinates} = object.geometry;
                var position;
                if (type == 'Point') {
                    position = coordinates;
                } else if (type == 'MultiPoint') {
                    position = coordinates;
                } else if (type == 'Polygon') {
                    position = turf.centerOfMass(object).geometry.coordinates;
                } else {
                    position = []
                }
                subLayerData.push(this.getSubLayerRow({position: [[21.288310076167328, 48.681988909757585]], name: object.properties.livingareaname}, object, index));
            });
            this.setState({subLayerData});
        }
    }
    renderLayers() {
        const {subLayerData} = this.state;
        const {getPosition, getFillColor, getLineColor, getLineWidth, updateTriggers, lineWidthScale, lineWidthMinPixels, getText, getTextSize, getTextColor, filled} = this.props;
        return [
            new GeoJsonLayer(this.getSubLayerProps({
                id: `geojson`,
                data: this.props.data,
                updateTriggers,
                getPosition: this.getSubLayerAccessor(getPosition),
                getFillColor: this.getSubLayerAccessor(getFillColor),
                getLineColor: this.getSubLayerAccessor(getLineColor),
                getLineWidth: this.getSubLayerAccessor(getLineWidth),
                filled: this.getSubLayerAccessor(filled),
                lineWidthScale: this.getSubLayerAccessor(lineWidthScale),
                lineWidthMinPixels: this.getSubLayerAccessor(lineWidthMinPixels),
            })),
            new TextLayer(this.getSubLayerProps({
                id: `label`,
                data: subLayerData,
                fontFamily: 'Poppins',
                background: true,
                backgroundPadding: [2, 2],
                getBackgroundColor: [235, 235, 235, 0],
                opacity: 1,
                getPosition: this.getSubLayerAccessor(getPosition),
                getText: this.getSubLayerAccessor(getText),
                getSize: this.getSubLayerAccessor(getTextSize),
                getColor: this.getSubLayerAccessor(getTextColor),
                updateTriggers,
            }))
        ];
    }
}

LabelledPolygonLayer.layerName = 'LabelledPolygonLayer';
LabelledPolygonLayer.defaultProps = {
    // Inherit all of GeoJsonLayer's props
    ...GeoJsonLayer.defaultProps,
    // Shared accessors
    getPosition: {type: 'accessor', value: x => x.position},
    // Label font
    fontFamily: 'Monaco, monospace',
    // Text accessors
    getText: {type: 'accessor', value: x => x.name},
    getTextSize: {type: 'accessor', value: 12},
    getTextColor: {type: 'accessor', value: [0, 0, 0, 255]}
};