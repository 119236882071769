import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import htmlForFeature from 'utils/htmlForFeature';
import { LEGEND_TYPES } from '@carto/react-ui';
import { useCallback } from 'react';
import colorScheme from 'assets/config/colorSchemes.json';

export const RWI_LAYER_ID = 'rwiLayer';

const defaultRwiLayerConfig = {
  id: RWI_LAYER_ID,
  title: 'RWI Layer',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.4,
  linecolor: [0, 0, 0],
  linewidth: 2,
  pointradius: 2,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    attr: 'rwi',
    type: LEGEND_TYPES.BINS,
    labels: [-1.5, -1, -0.5, 0, 0.4, 0.8, 1.2, 1.6],
    colorscheme: "RWIDefault",
    colors: colorScheme["RWIDefault"]
  }
};

var rwiLayerConfig = defaultRwiLayerConfig;

export function getRwiLayerConfig() {
  return rwiLayerConfig;
};

export function setRwiLayerConfig(newConfig=false) {
  if (newConfig === false) {
    rwiLayerConfig = defaultRwiLayerConfig;
  } else {
    rwiLayerConfig = newConfig;
  }
}

export default function RwiLayer() {
  const dispatch = useDispatch();
  const { rwiLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, rwiLayer?.source));
  const cartoLayerProps = useCartoLayerProps({ source });

  const getFilledColor = useCallback(
    (object) => {
      if (rwiLayer) {
        for (var i = 0; i < rwiLayerConfig.legend.labels.length; i++) {
          if (object.properties[rwiLayerConfig.legend.attr] <= rwiLayerConfig.legend.labels[i]) {
            return rwiLayerConfig.legend.colors[i];
          } 
        }
        return rwiLayerConfig.legend.colors[rwiLayerConfig.legend.labels.length-1];
      }
    }, [rwiLayer]
  );

  if (rwiLayer && source) {
    return new CartoLayer({
      ...cartoLayerProps,
      id: RWI_LAYER_ID,
      geoColumn: 'h3',
      aggregationExp: 'ROUND(AVG(rwi),3) AS rwi',
      getElevation: d => 0,
      filled: rwiLayerConfig.filled,
      getFillColor: getFilledColor,
      opacity : rwiLayerConfig.opacity,
      pointRadiusMinPixels: rwiLayerConfig.pointradius,
      headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
      getLineColor: rwiLayerConfig.linecolor,
      lineWidthMinPixels: rwiLayerConfig.linewidth,
      pickable: true,
      onDataLoad: (data) => {
        dispatch(
          updateLayer({
            id: RWI_LAYER_ID,
            layerAttributes: { ...rwiLayerConfig },
          })
        );
        cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
      },
      updateTriggers: {
        getFillColor: rwiLayerConfig,
        getFillColor: rwiLayerConfig.legend,
        getLineColor: rwiLayerConfig,
        opacity: rwiLayerConfig,
      },
      onHover: (info) => {
        if (info?.object) {
          info.object = {
            html: htmlForFeature({ feature: info.object }),
            style: {},
          };
        }
      },
    });
  }
}
