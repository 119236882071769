import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer, colorBins } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colorScheme from 'assets/config/colorSchemes.json';

export const FRA_GIRONDE_SPH3_LAYER_ID = 'fraGirondeSph3Layer';

const defaultIpLayerConfig = {
  id: FRA_GIRONDE_SPH3_LAYER_ID,
  title: 'IP Data',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.4,
  linecolor: [255, 0, 0],
  linewidth: 1,
  aggreslevel: 4,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    attr: 'total',
    type: LEGEND_TYPES.BINS,
    labels: [1, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000],
    colorscheme: "IPDefault",
    colors: colorScheme["IPDefault"]
  }
};

var ipLayerConfig = defaultIpLayerConfig;

export function getIPLayerConfig() {
  return ipLayerConfig;
};

export function setIPLayerConfig(newConfig=false) {
  if (newConfig === false) {
    ipLayerConfig = defaultIpLayerConfig;
  } else {
    ipLayerConfig = newConfig;
  }
}


export default function FraGirondeSph3Layer() {
  const dispatch = useDispatch();
  const { fraGirondeSph3Layer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, fraGirondeSph3Layer?.source));
  const cartoLayerProps = useCartoLayerProps({ source });

  if (fraGirondeSph3Layer && source) {
    return new CartoLayer({
      ...cartoLayerProps,
      id: FRA_GIRONDE_SPH3_LAYER_ID,
      geoColumn: 'h3',
      aggregationExp: `SUM(visits) AS total,SUM(visitors) AS visitors,SUM(tourists) AS tourists,SUM(residents) AS residents,SUM(workers) AS workers,SUM(total_population_sum) AS total_population,
	    SUM(households_sum) AS households,SUM(populationpermil_sum) AS populationpermil,AVG(purchasingpowerpercapita_avg) AS purchasingpowerpercapita,
	    AVG(agebelow15pct_avg) AS agebelow15pct_avg, AVG(age15to59pct_avg) AS age15to59pct_avg, AVG(age60abovepct_avg) AS age60abovepct_avg,
            SUM(mon) AS monday, SUM(tue) AS tuesday, SUM(wed) AS wednesday, SUM(thu) AS thursday, SUM(fri) AS friday, SUM(sat) AS saturday, SUM(sun) AS sunday, 
	    SUM(h0) AS h0, SUM(h4) AS h4, SUM(h8) AS h8, SUM(h12) AS h12, SUM(h16) AS h16, SUM(h20) AS h20, SUM(visitorid) AS visitorid, SUM(touristid) AS touristid, SUM(workerid) AS workerid,
	    SUM(residentid) AS residentid,SUM(uniqueids) AS uniqueids, (AVG(agebelow15pct_avg)*7) + (AVG(age15to59pct_avg) * 37) + (AVG(age60abovepct_avg) * 60) AS avg_age
	    `,
      headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
      aggregationResLevel: ipLayerConfig.aggreslevel,
      getElevation: d => 0,
      opacity: ipLayerConfig.opacity,
      getFillColor: colorBins({
        attr: ipLayerConfig.legend.attr,
        domain: ipLayerConfig.legend.labels,
        colors: ipLayerConfig.legend.colors,
      }),
      pointRadiusMinPixels: 2,
      onDataLoad: (data) => {
        dispatch(
          updateLayer({
            id: FRA_GIRONDE_SPH3_LAYER_ID,
            layerAttributes: { ...ipLayerConfig },
          })
        );
        cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
      },
      getLineColor: ipLayerConfig.linecolor,
      lineWidthMinPixels: ipLayerConfig.linewidth,
      pickable: true,
      updateTriggers: {
        getFillColor: ipLayerConfig,
        getFillColor: ipLayerConfig.legend,
        getLineColor: ipLayerConfig,
        opacity: ipLayerConfig,
      },
      onHover: (info) => {
        if (info?.object) {
          info.object = {
            html: "<strong>Total Visits</strong>: " + info.object.properties.total 
            + "<br/><strong>Total_Unique_IP_detected</strong>: " + info.object.properties.uniqueids 
            + "<br/><strong>Visitors</strong>: " + info.object.properties.visitorid 
            + "<br/><strong>Workers</strong>: " + info.object.properties.workerid 
            + "<br/><strong>Residents</strong>: " + info.object.properties.residentid 
            + "<br/><strong>Tourists</strong>: " + info.object.properties.touristid
            + "<br/><strong>Total Population</strong>: " + Math.round(info.object.properties.total_population) 
            + "<br/><strong>Total Households</strong>: " + (Math.round(info.object.properties.households*100)/100) 
            + "<br/><strong>Purchasing power/capita</strong>: " + Math.round(info.object.properties.purchasingpowerpercapita)
            + "<br/><strong>Average Age</strong>: " + (Math.round(info.object.properties.avg_age * 100) / 100),
            style: {},
          };
        }
      },
    });
  }
}
