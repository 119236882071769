import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { useCallback } from 'react';
import { LEGEND_TYPES } from '@carto/react-ui';
import colorScheme from 'assets/config/colorSchemes.json';

export const ONLINE_TURNOVER_LAYER_ID = 'onlineTurnoverLayer';

const defaultOnlineTurnoverLayerConfig = {
  id: ONLINE_TURNOVER_LAYER_ID,
  title: 'Online Turnover Layer',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.4,
  linecolor: [0, 0, 0],
  linewidth: 1,
  pointradius: 2,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    attr: 'online_to',
    type: LEGEND_TYPES.BINS,
    labels: [25000, 50000, 100000, 250000, 500000, 750000, 1000000],
    colorscheme: "OnlineTurnoverDefault",
    colors: colorScheme["OnlineTurnoverDefault"]
  }
};

var onlineTurnoverLayerConfig = defaultOnlineTurnoverLayerConfig;

export function getOnlineTurnoverLayerConfig() {
  return onlineTurnoverLayerConfig;
};

export function setOnlineTurnoverLayerConfig(newConfig=false) {
  if (newConfig === false) {
    onlineTurnoverLayerConfig = defaultOnlineTurnoverLayerConfig;
  } else {
    onlineTurnoverLayerConfig = newConfig;
  }
}

export default function OnlineTurnoverLayer() {
  const dispatch = useDispatch();
  const { onlineTurnoverLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, onlineTurnoverLayer?.source));
  const cartoLayerProps = useCartoLayerProps({ source });

  const getFilledColor = useCallback(
    (object) => {
      if (onlineTurnoverLayer) {
        for (var i = 0; i < onlineTurnoverLayerConfig.legend.labels.length; i++) {
          if (object.properties[onlineTurnoverLayerConfig.legend.attr] <= onlineTurnoverLayerConfig.legend.labels[i]) {
            return onlineTurnoverLayerConfig.legend.colors[i];
          } 
        }
        return onlineTurnoverLayerConfig.legend.colors[onlineTurnoverLayerConfig.legend.labels.length-1];
      }
    }, [onlineTurnoverLayer]
  );

  if (onlineTurnoverLayer && source) {
    return new CartoLayer({
      ...cartoLayerProps,
      id: ONLINE_TURNOVER_LAYER_ID,
      filled: onlineTurnoverLayerConfig.filled,
      getFillColor: getFilledColor,
      opacity : onlineTurnoverLayerConfig.opacity,
      pointRadiusMinPixels: onlineTurnoverLayerConfig.pointradius,
      headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
      getLineColor: onlineTurnoverLayerConfig.linecolor,
      lineWidthMinPixels: onlineTurnoverLayerConfig.linewidth,
      pickable: true,
      onDataLoad: (data) => {
        dispatch(
          updateLayer({
            id: ONLINE_TURNOVER_LAYER_ID,
            layerAttributes: { ...onlineTurnoverLayerConfig },
          })
        );
        cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
      },
      updateTriggers: {
        getFillColor: onlineTurnoverLayerConfig,
        getFillColor: onlineTurnoverLayerConfig.legend,
        getLineColor: onlineTurnoverLayerConfig,
        opacity: onlineTurnoverLayerConfig,
      },
      onHover: (info) => {
        if (info?.object) {
          info.object = {
            html: "<strong>Zipcode</strong>: " + info.object.properties.zipcode
                + "<br/><strong>Online Turnover</strong>: " + parseFloat(info.object.properties.online_to).toFixed(2)
                + "<br/><strong>Online Transactions</strong>: " + parseFloat(info.object.properties.online_transaction_id).toFixed(2)
                + "<br/><strong>Total Turnover</strong>: " + (parseFloat(info.object.properties.online_to) + parseFloat(info.object.properties.physical_to)).toFixed(2)
                + "<br/><strong>Online Turnover Percentage</strong>: " + (100 * parseFloat(info.object.properties.online_to) / (parseFloat(info.object.properties.online_to) + parseFloat(info.object.properties.physical_to))).toFixed(2) + "%",
            style: {},
          };
        }
      },
    });
  }
}
