import { useSelector } from 'react-redux';
import { CartoLayer, colorBins } from '@deck.gl/carto';
import { selectSourceById } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colorScheme from 'assets/config/colorSchemes.json';

export const FRA_GIRONDE_TIMESERIES_LAYER_ID = 'fraGirondeTimeseriesLayer';


const defaultTsLayerConfig = {
  id: FRA_GIRONDE_TIMESERIES_LAYER_ID,
  title: 'Time Series',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.2,
  linecolor: [255, 0, 0],
  linewidth: 1,
  aggreslevel: 4,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    attr: 'uniqueids',
    type: LEGEND_TYPES.BINS,
    labels: [0, 2, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000],
    colorscheme: "IPDefault",
    colors: colorScheme["IPDefault"]
  }
};

var tsLayerConfig = defaultTsLayerConfig;


export function getTsLayerConfig() {
  return tsLayerConfig;
};

export function setTsLayerConfig(newConfig=false) {
  if (newConfig === false) {
    tsLayerConfig = defaultTsLayerConfig;
  } else {
    tsLayerConfig = newConfig;
  }
}

export default function FraGirondeTimeseriesLayer() {
  const { fraGirondeTimeseriesLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, fraGirondeTimeseriesLayer?.source));
  const cartoLayerProps = useCartoLayerProps({ source });

  if (fraGirondeTimeseriesLayer && source) {
    const timewindow = fraGirondeTimeseriesLayer.timewindow;
    var attr = 'uniqueids';
    if (timewindow !== undefined) {
      if (timewindow == 0) {
        tsLayerConfig.legend.attr = 'id0';
      } else if (timewindow == 1) {
        tsLayerConfig.legend.attr = 'id2';
      } else if (timewindow == 2) {
        tsLayerConfig.legend.attr = 'id4';
      } else if (timewindow == 3) {
        tsLayerConfig.legend.attr = 'id6';
      } else if (timewindow == 4) {
        tsLayerConfig.legend.attr = 'id8';
      } else if (timewindow == 5) {
        tsLayerConfig.legend.attr = 'id10';
      } else if (timewindow == 6) {
        tsLayerConfig.legend.attr = 'id12';
      } else if (timewindow == 7) {
        tsLayerConfig.legend.attr = 'id14';
      } else if (timewindow == 8) {
        tsLayerConfig.legend.attr = 'id16';
      } else if (timewindow == 9) {
        tsLayerConfig.legend.attr = 'id18';
      } else if (timewindow == 10) {
        tsLayerConfig.legend.attr = 'id20';
      } else if (timewindow == 11) {
        tsLayerConfig.legend.attr = 'id22';
      }
    } else {
      tsLayerConfig.legend.attr = 'uniqueids';
    }
    return new CartoLayer({
      ...cartoLayerProps,
      id: FRA_GIRONDE_TIMESERIES_LAYER_ID,
      opacity: tsLayerConfig.opacity,
      getFillColor: colorBins({
        attr: tsLayerConfig.legend.attr,
        domain: tsLayerConfig.legend.labels,
        colors: tsLayerConfig.legend.colors,
      }),
      pointRadiusMinPixels: 2,
      getLineColor: colorBins({
        attr: tsLayerConfig.legend.attr,
        domain: tsLayerConfig.legend.labels,
        colors: tsLayerConfig.legend.colors,
      }),
      lineWidthMinPixels: 1,
      pickable: true,
      updateTriggers: {
        getFillColor: tsLayerConfig.legend.attr,
        getLineColor: tsLayerConfig.legend.attr,
        opacity: tsLayerConfig,
        onHover: tsLayerConfig,
      },
      onHover: (info) => {
        if (info?.object) {
          info.object = {
	          html: "<strong>uniqueids</strong>: " + info.object.properties[tsLayerConfig.legend.attr],
            style: {},
          };
        }
      },
    });
  }
}
