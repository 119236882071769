import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_SHOPLOTS_LAYER_ID = 'poiShoplotsLayer';

const defaultPoiShoplotsLayerConfig = {
    id: POI_SHOPLOTS_LAYER_ID,
    title: 'POI Shoplots Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Shop lots"],
      colorscheme: "ShoplotsDefault",
      colors: [colors["ShoplotsDefault"]]
    }
};

var poiShoplotsLayerConfig = defaultPoiShoplotsLayerConfig;

export function getPoiShoplotsLayerConfig() {
  return poiShoplotsLayerConfig;
};

export function setPoiShoplotsLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiShoplotsLayerConfig = defaultPoiShoplotsLayerConfig;
  } else {
    poiShoplotsLayerConfig = newConfig;
  }
}

export default function PoiShoplotsLayer() {
    const dispatch = useDispatch();
    const { poiShoplotsLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiShoplotsLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiShoplotsLayer) {
            var currConfig = poiShoplotsLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiShoplotsLayer]
    );

    if (poiShoplotsLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_SHOPLOTS_LAYER_ID,
            filled: poiShoplotsLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiShoplotsLayerConfig.opacity,
            pointRadiusMinPixels: poiShoplotsLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_SHOPLOTS_LAYER_ID,
                    layerAttributes: { ...poiShoplotsLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiShoplotsLayerConfig.linecolor,
            lineWidthMinPixels: poiShoplotsLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiShoplotsLayerConfig,
                getFillColor: poiShoplotsLayerConfig.legend,
                pointRadiusMinPixels: poiShoplotsLayerConfig,
                getLineColor: poiShoplotsLayerConfig,
                lineWidthMinPixels: poiShoplotsLayerConfig,
                opacity: poiShoplotsLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}