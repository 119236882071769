import { CompositeLayer, TextLayer } from 'deck.gl';
import { IconLayer } from '@deck.gl/layers';
import spritesheet from 'assets/img/spritesheet_newlogo.png';
import * as turf from '@turf/turf';

export default class LabelledIconLayer extends CompositeLayer {
    updateState({props, changeFlags}) {
        if (changeFlags.dataChanged) {
            const subLayerData = [];
            props.data.forEach((object, index) => {
                var {type, coordinates} = object.geometry;
                var position;
                if (type == 'Point') {
                    position = coordinates;
                } 
                subLayerData.push(this.getSubLayerRow({position: [[21.288310076167328, 48.681988909757585]], name: object.properties.id}, object, index));
            });
            this.setState({subLayerData});
        }
    }
    renderLayers() {
        const {subLayerData} = this.state;
        const {iconMapping, iconAtlas, updateTriggers, getIcon, sizeScale, getPosition, getSize, getColor, getText, getTextSize, getTextColor, getTextPosition, onClick} = this.props;
        return [
            new IconLayer(this.getSubLayerProps({
                id: `icon`,
                data: this.props.data,
                iconAtlas: spritesheet,
                iconMapping: this.getSubLayerAccessor(iconMapping),
                updateTriggers: this.getSubLayerAccessor(updateTriggers),
                getIcon: this.getSubLayerAccessor(getIcon),
                sizeScale: this.getSubLayerAccessor(sizeScale),
                getPosition: this.getSubLayerAccessor(getPosition),
                getSize: this.getSubLayerAccessor(getSize),
                getColor: this.getSubLayerAccessor(getColor),
                onClick: this.getSubLayerAccessor(onClick),
            })),
            new TextLayer(this.getSubLayerProps({
                id: `label`,
                data: subLayerData,
                fontFamily: 'Poppins',
                background: true,
                backgroundPadding: [2, 2],
                getBackgroundColor: [235, 235, 235, 75],
                opacity: 1,
                getPosition: this.getSubLayerAccessor(getTextPosition),
                getText: this.getSubLayerAccessor(getText),
                getSize: this.getSubLayerAccessor(getTextSize),
                getColor: this.getSubLayerAccessor(getTextColor),
                updateTriggers,
            }))
        ];
    }
}

LabelledIconLayer.layerName = 'LabelledIconLayer';
LabelledIconLayer.defaultProps = {
    // Inherit all of IconLayer's props
    ...IconLayer.defaultProps,
};