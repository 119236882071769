import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_INDUSTRIALAREA_LAYER_ID = 'poiIndustrialareaLayer';

const defaultPoiIndustrialareaLayerConfig = {
    id: POI_INDUSTRIALAREA_LAYER_ID,
    title: 'POI Industrial area Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Industrial area"],
      colorscheme: "IndustrialareaDefault",
      colors: [colors["IndustrialareaDefault"]]
    }
};

var poiIndustrialareaLayerConfig = defaultPoiIndustrialareaLayerConfig;

export function getPoiIndustrialareaLayerConfig() {
  return poiIndustrialareaLayerConfig;
};

export function setPoiIndustrialareaLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiIndustrialareaLayerConfig = defaultPoiIndustrialareaLayerConfig;
  } else {
    poiIndustrialareaLayerConfig = newConfig;
  }
}

export default function PoiIndustrialareaLayer() {
    const dispatch = useDispatch();
    const { poiIndustrialareaLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiIndustrialareaLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiIndustrialareaLayer) {
            var currConfig = poiIndustrialareaLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiIndustrialareaLayer]
    );

    if (poiIndustrialareaLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_INDUSTRIALAREA_LAYER_ID,
            filled: poiIndustrialareaLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiIndustrialareaLayerConfig.opacity,
            pointRadiusMinPixels: poiIndustrialareaLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_INDUSTRIALAREA_LAYER_ID,
                    layerAttributes: { ...poiIndustrialareaLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiIndustrialareaLayerConfig.linecolor,
            lineWidthMinPixels: poiIndustrialareaLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiIndustrialareaLayerConfig,
                getFillColor: poiIndustrialareaLayerConfig.legend,
                pointRadiusMinPixels: poiIndustrialareaLayerConfig,
                getLineColor: poiIndustrialareaLayerConfig,
                lineWidthMinPixels: poiIndustrialareaLayerConfig,
                opacity: poiIndustrialareaLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}