import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colorScheme from 'assets/config/colorSchemes.json';
import { useCallback } from 'react';

export const FRA_GIRONDE_SP_PURCHASING_LAYER_ID = 'fraGirondeSpPurchasingLayer';

const defaultPurchasingH3LayerConfig = {
  id: FRA_GIRONDE_SP_PURCHASING_LAYER_ID,
  title: 'Purchasing Power H3 Layer',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.4,
  linecolor: [255, 0, 0],
  linewidth: 1,
  aggreslevel: 4,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    attr: 'purchasingpowerpercapita',
    type: LEGEND_TYPES.BINS,
    labels: [16000, 17000, 17500, 18000, 18500, 19000, 19500, 20000, 20500, 21500, 22500, 23500, 24000, 24500, 25000, 25500],
    colorscheme: "PurchasingH3Default",
    colors: colorScheme["PurchasingH3Default"]
  }
};

var purchasingH3LayerConfig = defaultPurchasingH3LayerConfig;

export function getPurchasingH3LayerConfig() {
  return purchasingH3LayerConfig;
};

export function setPurchasingH3LayerConfig(newConfig=false) {
  if (newConfig === false) {
    purchasingH3LayerConfig = defaultPurchasingH3LayerConfig;
  } else {
    purchasingH3LayerConfig = newConfig;
  }
}

export default function FraGirondeSpPurchasingLayer() {
  const dispatch = useDispatch();
  const { fraGirondeSpPurchasingLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, fraGirondeSpPurchasingLayer?.source));
  const cartoLayerProps = useCartoLayerProps({ source });

  const getFilledColor = useCallback(
    (object) => {
      if (fraGirondeSpPurchasingLayer) {
        var currConfig = purchasingH3LayerConfig;
        for (var i = 0; i < currConfig.legend.labels.length; i++) {
          if (object.properties[currConfig.legend.attr] <= currConfig.legend.labels[i]) {
            return currConfig.legend.colors[i];
          } 
        }
        return currConfig.legend.colors[currConfig.legend.labels.length-1];
      }
    }, [fraGirondeSpPurchasingLayer]
  );

  if (fraGirondeSpPurchasingLayer && source) {
    return new CartoLayer({
      ...cartoLayerProps,
      id: FRA_GIRONDE_SP_PURCHASING_LAYER_ID,
      geoColumn: 'h3',
      aggregationExp: `SUM(visits) AS total,SUM(visitors) AS visitors,SUM(tourists) AS tourists,SUM(residents) AS residents,SUM(workers) AS workers,SUM(total_population_sum) AS total_population,
	    ROUND(SUM(households_sum),2) AS households,ROUND(SUM(populationpermil_sum),5) AS populationpermil,ROUND(AVG(purchasingpowerpercapita_avg)) AS purchasingpowerpercapita,
	    ROUND(AVG(agebelow15pct_avg),2) AS agebelow15pct_avg,ROUND(AVG(age15to59pct_avg),2) AS age15to59pct_avg,ROUND(AVG(age60abovepct_avg),2) AS age60abovepct_avg,
            SUM(mon) AS monday, SUM(tue) AS tuesday, SUM(wed) AS wednesday, SUM(thu) AS thursday, SUM(fri) AS friday, SUM(sat) AS saturday, SUM(sun) AS sunday, 
	    SUM(h0) AS h0, SUM(h4) AS h4, SUM(h8) AS h8, SUM(h12) AS h12, SUM(h16) AS h16, SUM(h20) AS h20, SUM(visitorid) AS visitorid, SUM(touristid) AS touristid, SUM(workerid) AS workerid,
	    SUM(residentid) AS residentid,SUM(uniqueids) AS uniqueids, ROUND((AVG(agebelow15pct_avg)*7) + (AVG(age15to59pct_avg) * 37) + (AVG(age60abovepct_avg) * 60),2) AS avg_age
	    `,
      headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
      aggregationResLevel: purchasingH3LayerConfig.aggreslevel,
      getElevation: d => 0,
      opacity: purchasingH3LayerConfig.opacity,
      getFillColor: getFilledColor,
      pointRadiusMinPixels: 2,
      onDataLoad: (data) => {
        dispatch(
          updateLayer({
            id: FRA_GIRONDE_SP_PURCHASING_LAYER_ID,
            layerAttributes: { ...purchasingH3LayerConfig },
          })
        );
        cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
      },
      getLineColor: purchasingH3LayerConfig.linecolor,
      lineWidthMinPixels: purchasingH3LayerConfig.linewidth,
      pickable: true,
      updateTriggers: {
        getFillColor: purchasingH3LayerConfig,
        getFillColor: purchasingH3LayerConfig.legend,
        getLineColor: purchasingH3LayerConfig,
        opacity: purchasingH3LayerConfig,
      },
      onHover: (info) => {
        if (info?.object) {
          info.object = {
            html: "<strong>Total Visits</strong>: " + info.object.properties.total 
            + "<br/><strong>Total_Unique_IP_detected</strong>: " + info.object.properties.uniqueids 
            + "<br/><strong>Visitors</strong>: " + info.object.properties.visitorid 
            + "<br/><strong>Workers</strong>: " + info.object.properties.workerid 
            + "<br/><strong>Residents</strong>: " + info.object.properties.residentid 
            + "<br/><strong>Tourists</strong>: " + info.object.properties.touristid
            + "<br/><strong>Total Population</strong>: " + Math.round(info.object.properties.total_population) 
            + "<br/><strong>Total Households</strong>: " + (Math.round(info.object.properties.households*100)/100) 
            + "<br/><strong>Purchasing power/capita</strong>: " + Math.round(info.object.properties.purchasingpowerpercapita)
            + "<br/><strong>Average Age</strong>: " + (Math.round(info.object.properties.avg_age*100)/100),
            style: {},
          };
        }
      },
    });
  }
}
