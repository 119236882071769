import { MAP_TYPES } from '@deck.gl/carto';
import moment from 'moment';
import { city, country } from 'utils/urlParamUtil';

const FRA_GIRONDE_SP_SOURCE_ID = 'fraGirondeSpSource';

const today = moment().format('YYYY-MM-DD');
const startDate = moment(today,'YYYY-MM-DD').subtract(3, 'month').format('YYYY-MM-DD');

const source = {
  id: FRA_GIRONDE_SP_SOURCE_ID,
  type: MAP_TYPES.QUERY,
  connection: process.env.REACT_APP_CONNECTION_NAME,
  data:`
      SELECT 
        ip.*, 
        demo.* except (h3)
      FROM
        (
          SELECT
            h3,
            SUM(visits) AS visits,
            COUNT(DISTINCT hashed_device_id) AS uniqueids,
            SUM(visitors) AS visitors,
            SUM(residents) AS residents,
            SUM(workers) AS workers,
            SUM(tourists) AS tourists,
            COUNT(DISTINCT visitorid) AS visitorid,
            COUNT(DISTINCT residentid) AS residentid,
            COUNT(DISTINCT workerid) AS workerid,
            COUNT(DISTINCT touristid) AS touristid,
            SUM(mon) AS mon,
            SUM(tue) AS tue,
            SUM(wed) AS wed,
            SUM(thu) AS thu,
            SUM(fri) AS fri,
            SUM(sat) AS sat,
            SUM(sun) AS sun,
            SUM(h0+h2) AS h0,
            SUM(h4+h6) AS h4,
            SUM(h8+h10) AS h8,
            SUM(h12+h14) AS h12,
            SUM(h16+h18) AS h16,
            SUM(h20+h22) AS h20
          FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.SUMMARY_IP_${country}_${city}\` 
          WHERE
            type IN UNNEST(@types) AND
            day_of_week in UNNEST(@day) AND
            CAST(hour AS STRING) in UNNEST(@hours) AND
            date BETWEEN CAST(@startDate AS DATE FORMAT 'YYYY-MM-DD') AND CAST(@endDate AS DATE FORMAT 'YYYY-MM-DD')
          GROUP BY 
            h3
        ) ip
      LEFT JOIN
        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.demography_h3_${country}_${city}\` demo
      ON 
        ip.h3 = demo.h3
      WHERE 
        (
          agebelow15pct_avg IS NULL 
          OR (
            agebelow15pct_avg BETWEEN CAST(@agepct[OFFSET(0)] AS NUMERIC) AND CAST(@agepct[OFFSET(1)] AS NUMERIC)
            AND age15to59pct_avg BETWEEN CAST(@agepct[OFFSET(2)] AS NUMERIC) AND CAST(@agepct[OFFSET(3)] AS NUMERIC)
            AND age60abovepct_avg BETWEEN CAST(@agepct[OFFSET(4)] AS NUMERIC) AND CAST(@agepct[OFFSET(5)] AS NUMERIC)
          )
        )
  `,
  queryParameters:{
	  'types':["Visitor","Resident","Worker","Tourist"],
	  'agepct':["0","100","0","100","0","100"],
	  'day':["Mon","Tue","Wed","Thu","Fri","Sat","Sun"],
	  'hours': ["0","2","4","6","8","10","12","14","16","18","20","22"],
	  'startDate': String(startDate), 'endDate': String(today)
  }
};

export default source;
