import { useSelector } from 'react-redux';
import htmlForFeature from 'utils/htmlForFeature';
import { GeoJsonLayer } from '@deck.gl/layers';
import { LEGEND_TYPES } from '@carto/react-ui';
import { useCallback } from 'react';
import colors from 'assets/config/colors.json';

export const CITY_BOUNDARY_LAYER_ID = 'cityBoundaryLayer';

const defaultCityBoundaryLayerConfig = {
  id: CITY_BOUNDARY_LAYER_ID,
  title: 'Region / Agglo boundary',
  visible: false,
  switchable: false,
  filled: false,
  opacity: 0.4,
  linecolor: colors["CityBoundaryDefault"],
  linewidth: 4,
  pointradius: 2,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    type: LEGEND_TYPES.CATEGORY,
    labels: ["Region / Agglo boundary"],
    colorscheme: "LightGrey",
    colors: [colors["LightGrey"]]
  }
};

var cityBoundaryLayerConfig = defaultCityBoundaryLayerConfig;

export function getCityBoundaryLayerConfig() {
  return cityBoundaryLayerConfig;
};

export function setCityBoundaryLayerConfig(newConfig=false) {
  if (newConfig === false) {
    cityBoundaryLayerConfig = defaultCityBoundaryLayerConfig;
  } else {
    cityBoundaryLayerConfig = newConfig;
  }
}

export default function CityBoundaryLayer() {
  const { cityBoundaryLayer } = useSelector((state) => state.carto.layers);
  const cityBoundary = useSelector((state) => state.polygon.cityBoundary);
  const selectedId = useSelector((state) => state.polygon.selectedPolygonId);

  const getFilledColor = useCallback(
    () => {
      if (cityBoundaryLayer) {
	      return cityBoundaryLayerConfig.legend.colors[0];
      }
    }, [cityBoundaryLayer]
  );

  const getLinesColor = useCallback(
    (object) => {
      if (cityBoundaryLayer) {
	      if (object.properties.id !== null && object.properties.id === selectedId) {
          return colors["SelectedBoundaryDefault"];
	      }
	      return cityBoundaryLayerConfig.linecolor;
      }
    }, [cityBoundaryLayer]
  );

  if (cityBoundaryLayer && cityBoundary) {
    return new GeoJsonLayer({
      id: CITY_BOUNDARY_LAYER_ID,
      data: cityBoundary,
      pickable: true,
      filled: cityBoundaryLayerConfig.filled,
      lineWidthScale: 20,
      lineWidthMinPixels: cityBoundaryLayerConfig.linewidth,
      getFillColor: getFilledColor,
      opacity: cityBoundaryLayerConfig.opacity,
      getLineColor: getLinesColor,
      getLineWidth: 2,
      updateTriggers: {
        getFillColor: selectedId,
        getLineColor: selectedId,
        getFillColor: cityBoundaryLayerConfig,
        getFillColor: cityBoundaryLayerConfig.legend,
        pointRadiusMinPixels: cityBoundaryLayerConfig,
        getLineColor: cityBoundaryLayerConfig.linecolor,
        lineWidthMinPixels: cityBoundaryLayerConfig,
        opacity: cityBoundaryLayerConfig,
      },
      onHover: (info) => {
        if (info?.object) {
          let {geometry, avg_basket, id, sport_market_size, enriched, decath_digital_to, decath_physical_to, decath_visit_count, createdat, updatedat, visionid, name, ...object} = info.object.properties;
          var display = {...info.object};
          display.properties = object;
          for (let d in display.properties) {
            if (Number.isInteger(display.properties[d])) {
              display.properties[d] = Math.round(display.properties[d]*100) / 100;
            }
	        }
          info.object = {
            html: htmlForFeature({ feature: display }),
            style: {},
          };
        }
      },
    });
  }
}
