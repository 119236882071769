import { lazy } from 'react';
import ProtectedRoute from 'components/common/ProtectedRoute';
import DefaultView from 'components/common/DefaultView';
import ToEstimation from 'components/views/ToEstimation';

const Main = lazy(() => import(/* webpackPrefetch: true */ 'components/views/main/Main'));
const NotFound = lazy(() => import('components/views/NotFound'));
const Login = lazy(() => import('components/views/Login'));
const Ip = lazy(() => import('components/views/Ip.js'));
const Region = lazy(() => import('components/views/Region.js'));
const Maintenance = lazy(() => import('components/views/Maintenance.js'));
// [hygen] Import views

export const ROUTE_PATHS = {
  LOGIN: '/login',
  DEFAULT: '/',
  NOT_FOUND: '/404',
  IP: '/ip',
  REGION: '/region',
  MAINTENANCE: '/maintenance',
  TOESTIMATION: '/toestimation'
  // [hygen] Add path routes
};

var routes = [
  {
    path: ROUTE_PATHS.DEFAULT,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Main />
        </DefaultView>
      </ProtectedRoute>
    ),
    children: [
      // { path: '/', element: <Navigate to='/<your default view>' /> },
      { path: ROUTE_PATHS.IP, element: <Ip /> },
      { path: ROUTE_PATHS.REGION, element: <Region /> },
      { path: ROUTE_PATHS.TOESTIMATION, element: <ToEstimation /> },
      // [hygen] Add routes
    ],
  },
  { path: ROUTE_PATHS.LOGIN, element: <Login /> },
  { path: ROUTE_PATHS.MAINTENANCE, element: <Maintenance />},
  {
    path: '*',
    element: (
      <DefaultView>
        <NotFound />
      </DefaultView>
    ),
  },
];
if (process.env.REACT_APP_MAINTENANCE == 'true') {
  routes = [
    {
      path: '*',
      element: (
        <Maintenance />
      ),
    },
  ];
} 

export default routes;
