import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_COMMERCIALBUILDING_LAYER_ID = 'poiCommercialbuildingLayer';

const defaultPoiCommercialbuildingLayerConfig = {
    id: POI_COMMERCIALBUILDING_LAYER_ID,
    title: 'POI Commercialbuilding Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Commercial building"],
      colorscheme: "CommercialbuildingDefault",
      colors: [colors["CommercialbuildingDefault"]]
    }
};

var poiCommercialbuildingLayerConfig = defaultPoiCommercialbuildingLayerConfig;

export function getPoiCommercialbuildingLayerConfig() {
  return poiCommercialbuildingLayerConfig;
};

export function setPoiCommercialbuildingLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiCommercialbuildingLayerConfig = defaultPoiCommercialbuildingLayerConfig;
  } else {
    poiCommercialbuildingLayerConfig = newConfig;
  }
}

export default function PoiCommercialbuildingLayer() {
    const dispatch = useDispatch();
    const { poiCommercialbuildingLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiCommercialbuildingLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiCommercialbuildingLayer) {
            var currConfig = poiCommercialbuildingLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiCommercialbuildingLayer]
    );

    if (poiCommercialbuildingLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_COMMERCIALBUILDING_LAYER_ID,
            filled: poiCommercialbuildingLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiCommercialbuildingLayerConfig.opacity,
            pointRadiusMinPixels: poiCommercialbuildingLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_COMMERCIALBUILDING_LAYER_ID,
                    layerAttributes: { ...poiCommercialbuildingLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiCommercialbuildingLayerConfig.linecolor,
            lineWidthMinPixels: poiCommercialbuildingLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiCommercialbuildingLayerConfig,
                getFillColor: poiCommercialbuildingLayerConfig.legend,
                pointRadiusMinPixels: poiCommercialbuildingLayerConfig,
                getLineColor: poiCommercialbuildingLayerConfig,
                lineWidthMinPixels: poiCommercialbuildingLayerConfig,
                opacity: poiCommercialbuildingLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}