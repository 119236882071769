import { MAP_TYPES } from '@deck.gl/carto';
import { vision, city, country } from 'utils/urlParamUtil';

const SEGMENT_SOURCE_ID = 'segmentSource';

const source = {
  id: SEGMENT_SOURCE_ID,
  type: MAP_TYPES.QUERY,
  connection: process.env.REACT_APP_CONNECTION_NAME,
  data: `
    SELECT 
      *
    FROM 
      \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.segments_${vision}_${country}_${city}\` 
    WHERE 
      total_respondent > 6
  `,
  filters: {},
};

export default source;
