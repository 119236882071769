import { MAP_TYPES } from '@deck.gl/carto';
import { city, country, vision } from 'utils/urlParamUtil';

const PURCHASINGPOWER_SOURCE_ID = 'purchasingpowerSource';

const source = {
  id: PURCHASINGPOWER_SOURCE_ID,
  type: MAP_TYPES.QUERY,
  connection: process.env.REACT_APP_CONNECTION_NAME,
  data: `
    SELECT 
      geom, 
      CASE WHEN purchasingpowerpercapita IS NULL THEN 0 ELSE purchasingpowerpercapita END AS purchasingpowerpercapita,
      CASE WHEN purchasingpowerindex IS NULL THEN 0 ELSE purchasingpowerindex END AS purchasingpowerindex,
      CASE WHEN purchasingpowerpermil IS NULL THEN 0 ELSE purchasingpowerpermil END AS purchasingpowerpermil
    FROM
      \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.purchasingpowergeom_${vision}_${country}_${city}\`
  `,
};

export default source;
