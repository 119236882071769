import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_TOURISTAREA_LAYER_ID = 'poiTouristareaLayer';

const defaultPoiTouristareaLayerConfig = {
    id: POI_TOURISTAREA_LAYER_ID,
    title: 'POI Tourist area Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Tourist area"],
      colorscheme: "TouristareaDefault",
      colors: [colors["TouristareaDefault"]]
    }
};

var poiTouristareaLayerConfig = defaultPoiTouristareaLayerConfig;

export function getPoiTouristareaLayerConfig() {
  return poiTouristareaLayerConfig;
};

export function setPoiTouristareaLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiTouristareaLayerConfig = defaultPoiTouristareaLayerConfig;
  } else {
    poiTouristareaLayerConfig = newConfig;
  }
}

export default function PoiTouristareaLayer() {
    const dispatch = useDispatch();
    const { poiTouristareaLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiTouristareaLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiTouristareaLayer) {
            var currConfig = poiTouristareaLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiTouristareaLayer]
    );

    if (poiTouristareaLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_TOURISTAREA_LAYER_ID,
            filled: poiTouristareaLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiTouristareaLayerConfig.opacity,
            pointRadiusMinPixels: poiTouristareaLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_TOURISTAREA_LAYER_ID,
                    layerAttributes: { ...poiTouristareaLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiTouristareaLayerConfig.linecolor,
            lineWidthMinPixels: poiTouristareaLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiTouristareaLayerConfig,
                getFillColor: poiTouristareaLayerConfig.legend,
                pointRadiusMinPixels: poiTouristareaLayerConfig,
                getLineColor: poiTouristareaLayerConfig,
                lineWidthMinPixels: poiTouristareaLayerConfig,
                opacity: poiTouristareaLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}