import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { useCallback } from 'react';
import { LEGEND_TYPES } from '@carto/react-ui';
import colorScheme from 'assets/config/colorSchemes.json';

export const PHYSICAL_TURNOVER_LAYER_ID = 'physicalTurnoverLayer';

const defaultPhysicalTurnoverLayerConfig = {
  id: PHYSICAL_TURNOVER_LAYER_ID,
  title: 'Physical Turnover Layer',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.4,
  linecolor: [0, 0, 0],
  linewidth: 1,
  pointradius: 2,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    attr: 'physical_to',
    type: LEGEND_TYPES.BINS,
    labels: [0, 100000, 250000, 500000, 1000000, 2500000, 5000000, 10000000],
    colorscheme: "PhysicalTurnoverDefault",
    colors: colorScheme["PhysicalTurnoverDefault"]
  }
};

var physicalTurnoverLayerConfig = defaultPhysicalTurnoverLayerConfig;

export function getPhysicalTurnoverLayerConfig() {
  return physicalTurnoverLayerConfig;
};

export function setPhysicalTurnoverLayerConfig(newConfig=false) {
  if (newConfig === false) {
    physicalTurnoverLayerConfig = defaultPhysicalTurnoverLayerConfig;
  } else {
    physicalTurnoverLayerConfig = newConfig;
  }
}

export default function PhysicalTurnoverLayer() {
  const dispatch = useDispatch();
  const { physicalTurnoverLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, physicalTurnoverLayer?.source));
  const cartoLayerProps = useCartoLayerProps({ source });

  const getFilledColor = useCallback(
    (object) => {
      if (physicalTurnoverLayer) {
        for (var i = 0; i < physicalTurnoverLayerConfig.legend.labels.length; i++) {
          if (object.properties[physicalTurnoverLayerConfig.legend.attr] <= physicalTurnoverLayerConfig.legend.labels[i]) {
            return physicalTurnoverLayerConfig.legend.colors[i];
          } 
        }
        return physicalTurnoverLayerConfig.legend.colors[physicalTurnoverLayerConfig.legend.labels.length-1];
      }
    }, [physicalTurnoverLayer]
  );

  if (physicalTurnoverLayer && source) {
    return new CartoLayer({
      ...cartoLayerProps,
      id: PHYSICAL_TURNOVER_LAYER_ID,
      filled: physicalTurnoverLayerConfig.filled,
      getFillColor: getFilledColor,
      opacity : physicalTurnoverLayerConfig.opacity,
      pointRadiusMinPixels: physicalTurnoverLayerConfig.pointradius,
      headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
      getLineColor: physicalTurnoverLayerConfig.linecolor,
      lineWidthMinPixels: physicalTurnoverLayerConfig.linewidth,
      pickable: true,
      onDataLoad: (data) => {
        dispatch(
          updateLayer({
            id: PHYSICAL_TURNOVER_LAYER_ID,
            layerAttributes: { ...physicalTurnoverLayerConfig },
          })
        );
        cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
      },
      updateTriggers: {
        getFillColor: physicalTurnoverLayerConfig,
        getFillColor: physicalTurnoverLayerConfig.legend,
        getLineColor: physicalTurnoverLayerConfig,
        opacity: physicalTurnoverLayerConfig,
      },
      onHover: (info) => {
        if (info?.object) {
          info.object = {
            html: "<strong>Zipcode</strong>: " + info.object.properties.zipcode
                + "<br/><strong>Physical Turnover</strong>: " + parseFloat(info.object.properties.physical_to).toFixed(2)
                + "<br/><strong>Nb. of Physical Transactions (members)</strong>: " + parseFloat(info.object.properties.physical_transaction_id).toFixed(0)
                + "<br/><strong>Total Turnover</strong>: " + (parseFloat(info.object.properties.online_to) + parseFloat(info.object.properties.physical_to)).toFixed(2)
                + "<br/><strong>Physical Turnover Percentage</strong>: " + (100 * parseFloat(info.object.properties.physical_to) / (parseFloat(info.object.properties.online_to) + parseFloat(info.object.properties.physical_to))).toFixed(2) + "%"
                + "<br/><strong>(1) </strong>" + info.object.properties.storecode1 
                + " ( % Instore TO contribution: " + (100 * parseFloat(info.object.properties.storephysicalto1) / (parseFloat(info.object.properties.physical_to))).toFixed(2) + "% )"
                + "<br/><strong>(2) </strong>" + info.object.properties.storecode2 
                + " ( % Instore TO contribution: " + (100 * parseFloat(info.object.properties.storephysicalto2) / (parseFloat(info.object.properties.physical_to))).toFixed(2) + "% )"
                + "<br/><strong>(3) </strong>" + info.object.properties.storecode3 
                + " ( % Instore TO contribution: " + (100 * parseFloat(info.object.properties.storephysicalto3) / (parseFloat(info.object.properties.physical_to))).toFixed(2) + "% )"
                + "<br/><strong>(4) </strong>" + info.object.properties.storecode4 
                + " ( % Instore TO contribution: " + (100 * parseFloat(info.object.properties.storephysicalto4) / (parseFloat(info.object.properties.physical_to))).toFixed(2) + "% )"
                + "<br/><strong>(5) </strong>" + info.object.properties.storecode5 
                + " ( % Instore TO contribution: " + (100 * parseFloat(info.object.properties.storephysicalto5) / (parseFloat(info.object.properties.physical_to))).toFixed(2) + "% )"
                ,
            style: {},
          };
        }
      },
    });
  }
}
