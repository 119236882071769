import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_LOGISTICAREA_LAYER_ID = 'poiLogisticareaLayer';

const defaultPoiLogisticareaLayerConfig = {
    id: POI_LOGISTICAREA_LAYER_ID,
    title: 'POI Logisticarea Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Logistic area"],
      colorscheme: "LogisticareaDefault",
      colors: [colors["LogisticareaDefault"]]
    }
};

var poiLogisticareaLayerConfig = defaultPoiLogisticareaLayerConfig;

export function getPoiLogisticareaLayerConfig() {
  return poiLogisticareaLayerConfig;
};

export function setPoiLogisticareaLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiLogisticareaLayerConfig = defaultPoiLogisticareaLayerConfig;
  } else {
    poiLogisticareaLayerConfig = newConfig;
  }
}

export default function PoiLogisticareaLayer() {
    const dispatch = useDispatch();
    const { poiLogisticareaLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiLogisticareaLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiLogisticareaLayer) {
            var currConfig = poiLogisticareaLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiLogisticareaLayer]
    );

    if (poiLogisticareaLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_LOGISTICAREA_LAYER_ID,
            filled: poiLogisticareaLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiLogisticareaLayerConfig.opacity,
            pointRadiusMinPixels: poiLogisticareaLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_LOGISTICAREA_LAYER_ID,
                    layerAttributes: { ...poiLogisticareaLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiLogisticareaLayerConfig.linecolor,
            lineWidthMinPixels: poiLogisticareaLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiLogisticareaLayerConfig,
                getFillColor: poiLogisticareaLayerConfig.legend,
                pointRadiusMinPixels: poiLogisticareaLayerConfig,
                getLineColor: poiLogisticareaLayerConfig,
                lineWidthMinPixels: poiLogisticareaLayerConfig,
                opacity: poiLogisticareaLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}