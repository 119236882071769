import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import colors from 'assets/config/colors.json';
import { useCallback } from 'react';
import htmlForFeature from 'utils/htmlForFeature';

export const POI_STADIUM_LAYER_ID = 'poiStadiumLayer';

const defaultPoiStadiumLayerConfig = {
    id: POI_STADIUM_LAYER_ID,
    title: 'POI Stadium Layer',
    visible: false,
    switchable: false,
    filled: true,
    opacity: 0.6,
    linecolor: [0, 0, 0],
    linewidth: 2,
    pointradius: 4,
    showOpacityControl: false,
    legend: {
      collapsed: false,
      type: LEGEND_TYPES.CATEGORY,
      labels: ["Stadium"],
      colorscheme: "StadiumDefault",
      colors: [colors["StadiumDefault"]]
    }
};

var poiStadiumLayerConfig = defaultPoiStadiumLayerConfig;

export function getPoiStadiumLayerConfig() {
  return poiStadiumLayerConfig;
};

export function setPoiStadiumLayerConfig(newConfig=false) {
  if (newConfig === false) {
    poiStadiumLayerConfig = defaultPoiStadiumLayerConfig;
  } else {
    poiStadiumLayerConfig = newConfig;
  }
}

export default function PoiStadiumLayer() {
    const dispatch = useDispatch();
    const { poiStadiumLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, poiStadiumLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        () => {
          if (poiStadiumLayer) {
            var currConfig = poiStadiumLayerConfig;
            return currConfig.legend.colors[0];
          }
        }, [poiStadiumLayer]
    );

    if (poiStadiumLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: POI_STADIUM_LAYER_ID,
            filled: poiStadiumLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : poiStadiumLayerConfig.opacity,
            pointRadiusMinPixels: poiStadiumLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            onDataLoad: (data) => {
                dispatch(
                  updateLayer({
                    id: POI_STADIUM_LAYER_ID,
                    layerAttributes: { ...poiStadiumLayerConfig },
                  })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            getLineColor: poiStadiumLayerConfig.linecolor,
            lineWidthMinPixels: poiStadiumLayerConfig.linewidth,
            pickable: true,
            updateTriggers: {
                getFillColor: poiStadiumLayerConfig,
                getFillColor: poiStadiumLayerConfig.legend,
                pointRadiusMinPixels: poiStadiumLayerConfig,
                getLineColor: poiStadiumLayerConfig,
                lineWidthMinPixels: poiStadiumLayerConfig,
                opacity: poiStadiumLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                  info.object = {
                    html: htmlForFeature({ feature: info.object }),
                    style: {},
                  };
                }
            },
        });
    }
}