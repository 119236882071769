import { MAP_TYPES } from '@deck.gl/carto';
import { city, country, vision } from 'utils/urlParamUtil';

const TURNOVER_SOURCE_ID = 'turnoverSource';

const source = {
  id: TURNOVER_SOURCE_ID,
  type: MAP_TYPES.QUERY,
  connection: process.env.REACT_APP_CONNECTION_NAME,
  data: `
    SELECT 
      * 
    FROM 
      \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.turnover_${vision}_${country}_${city}\`
  `,
};

export default source;

